<template>
  <ClFormRow :label="label" :toolTipText="tooltip" :isLabel="isLabel" :isTooltip="isTooltip" ref="datePickerWrapper">
    <template #content>
      <div class="zq--data-picker--wrap" ref="datePicker">
        <date-picker
          :name="name"
          v-model="date"
          type="datetime"
          :placeholder="placeholder"
          format="ddd, DD MMMM YYYY HH:mm:ss UTC"
          :disabled-date="disabledDate"
          :disabled-time="disabledTime"
          :disabled="disabled"
          required
          class="zq--data-picker"
          popup-class="zq--data-picker--popup"
        >
          <template #icon-calendar>
            <img
              width="16"
              height="16"
              alt="calendar"
              src="../../../assets/icons/calendar/calendar.png"
              srcset="../../../assets/icons/calendar/calendar@2x.png 2x, ../../../assets/icons/calendar/calendar@3x.png 3x"
            >
          </template>
        </date-picker>
      </div>
    </template>
  </ClFormRow>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'ZqDatePicker',
  components: {
    DatePicker
  },
  props: {
    label: String,
    tooltip: String,
    isLabel: {
      type: Boolean,
      default: true,
    },
    isTooltip: {
      type: Boolean,
      default: true,
    },
    dateProp: [Date, String],
    placeholder: String,
    name: String,
    disabledDate: Function,
    disabledTime: Function,
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: null
    }
  },
  created() {
    if (this.dateProp) this.date = new Date(this.dateProp.getTime() + this.dateProp.getTimezoneOffset() * 60000);

    if (this.name === 'pointInTime' && this.$refs.datePickerWrapper) {
      this.$refs.datePickerWrapper.classList.add('defaultHide')
    }
  },
  watch: {
    dateProp(date) {
      this.date = date;
    },
    date: {
      deep: true,
      handler(val) {
        if (this.date !== null && this.$refs.datePickerWrapper) {
          this.$refs.datePicker.classList.remove('zq-invalid');
        }

        this.$emit('input', val)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins";

.zq--data-picker--wrap {
  width: 100%;
  position: relative;
  &__error {
    margin-bottom: 14px;
  }
  &.zq-invalid {
    border: solid 1px var(--zq-warn) !important;
    border-radius: 12px;
  }
  .c-nxt-app__main-theme .zq--data-picker.mx-datepicker .mx-input-wrapper .mx-icon-calendar {
    right: 26px;
  }
  .zq--data-picker.mx-datepicker {
    width: 100%;
    & .mx-input {
      max-width: calc(100% - 16px);
    }
  }
  .mx-time-header {
    .mx-btn, .mx-btn-text, .mx-time-header-title {
      &:before {
        content: '«';
      }
    }
  }
  .error {
    .mx-input {
      border: 1px solid red;

    }
    &:after {
      color: #e55353;
      position: absolute;
      content: 'Required';
      top: 3.6em;
      left: 0;
      font-size: 11px;
    }
  }
  .defaultHide {
    display: none;
  }
}
</style>